import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { UserButton } from '@clerk/clerk-react';
import FeedbackButton from './FeedbackButton';

function AppBarComponent() {
    return (
        <AppBar position="static" >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Split My Bill
                </Typography>
                <FeedbackButton />
                <UserButton />
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;