import { QuestionMarkRounded } from "@mui/icons-material"
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, useTheme } from "@mui/material"
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useCreateUserFeedbackMutation } from "../api/queries";
import { toast } from "react-toastify";

function FeedbackButton() {
    const theme = useTheme();

    const user = useSelector((state: any) => state.user);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    
    const [createUserFeedback, {isLoading}] = useCreateUserFeedbackMutation();

    const submitFeedback = async () => {
        try {
            await createUserFeedback({
                message: feedbackMessage,
                userId: user.userId
            })

            setFeedbackDialogOpen(false);
            setFeedbackMessage('');
            toast.success('Feedback has been captured. Thank you for letting us know!')
        } catch (e) {
            toast.error('Feedback could not be submitted, please try again later.')
        }
    }

    const handleClose = () => {
        setFeedbackDialogOpen(false);
        setFeedbackMessage('');
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2}}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={feedbackDialogOpen} onClose={handleClose} fullWidth sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <DialogTitle>Submit Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your feedback below:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Feedback Message"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={feedbackMessage}
                        multiline={true}
                        rows={5}
                        onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={submitFeedback} disabled={isLoading || (feedbackMessage.length === 0)}>Submit</Button>
                </DialogActions>
            </Dialog>
            <IconButton 
                aria-label='add feedback' 
                size='small'
                sx={{
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.common.black,
                    height: '27px',
                    width: '28px',
                    mr: 1
                }}
                onClick={() => setFeedbackDialogOpen(true)}
            >
                <QuestionMarkRounded />
            </IconButton>
        </>
    )
}

export default FeedbackButton